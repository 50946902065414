import request from '@/utils/request'

// 获取最近联系的人
export function queryAnswerList(params) {
  return request({
    url: 'answer/queryAnswerList',
    method: 'get',
    params
  })
}

